.main-international-shipping {
  align-self: flex-start;
  display: flex;
  flex-basis: 20%;
  padding-top: 13px;
}

.main-international-shipping button {
  outline: none;
}

.main-international-shipping button {
  outline: none;
}

.international-shipping-link {
  background: none;
  border: none;
  color: #4c94d0;
  cursor: pointer;
  font-size: 0.5625rem;
  font-style: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-bottom: 1px;
  text-align: left;
}
.international-shipping-text {
  border-bottom: 1px #4c94d0 dotted;
}

@media screen and (min-width: 480px) {
  .banner-default .main-international-shipping {
    align-self: center;
    flex-basis: inherit;
    justify-content: center;
    margin-top: -5px;
    padding-top: 0;
  }
  .banner-default .main-international-shipping button {
    text-align: center;
    width: 93px;
  }
}
