.custom-quote-wrapp {
  align-items: center;
  background: #fcf4e7;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.custom-quote-text {
  color: #000;
  display: flex;
  font-size: 12px;
  line-height: normal;
  margin-bottom: 13px;
}

.custom-quote-text span {
  margin-right: 5px;
}

.custom-quote-text button {
  font-size: 12px;
}

.custom-quote-wrapp a {
  background: #fa762e;
  border: 0;
  border-radius: 2px;
  color: #fcf4e7;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: normal;
  min-width: 8.125rem;
  outline: none;
  padding: 4px 0;
  text-align: center;
  text-decoration: none;
  width: 200px;
}
