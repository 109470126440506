@import './theme.scss';

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Segoe_UI/Segoe_UI.woff2') format('woff2'),
  url('/fonts/Segoe_UI/Segoe_UI.woff') format('woff');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Segoe_UI/Segoe_UI_Bold.woff2') format('woff2'),
  url('/fonts/Segoe_UI/Segoe_UI_Bold.woff') format('woff');
}

.main {
  overflow: hidden;
}

.btn {
  -webkit-appearance: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  margin: 0;
  overflow: visible;
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  width: auto;

  &--primary {
    background-color: $primary-iaai;
    box-shadow: 0 2px 2px rgba(0,0,0,.2);
    color: #fff;
    font-weight: 600;

    @media (max-width: $mobile) {
      background-color: $secondary-iaai;
      text-transform: uppercase;
    }
  }

  &--secondary {
    background-color: $primary;
    color: #fff;

    @media (max-width: $mobile) {
      background-color: $secondary-iaai;
      text-transform: uppercase;
    }
  }

  &--tiny {
    border-radius: 8px;
    font-size: 8px;
    line-height: 11px;
    padding: 0 4px;
    text-transform: uppercase;
    vertical-align: middle;
    @media (max-width: $mobile) {
      font-size: 10px;
      padding: 2px 7px;
    }
  }

  &--huge {
    @media (max-width: $mobile) {
      display: block;
      font-size: 14px;
      padding: 5px 15px;
      width: 100%;
    }
  }
}

.icon {
  &--arrow {
    opacity: .8;
  }
}

.btn-close {
  @include reset-btn;
  font-size: 0;
  height: 13px;
  outline: none;
  position: absolute;
  transition: opacity 0.3s;
  width: 13px;
  z-index: 1;

  &:hover, &:active, &:focus {
    opacity: 0.8
  }
}

.btn-icon {
  @include reset-btn;
  border: 1px solid $secondary-1000;
  border-radius: 100%;
  color: $secondary-1000;
  display: inline-block;
  font-size: 9px;
  font-weight: 700;
  height: 13px;
  outline: none;
  padding: 0;
  width: 13px;

  &:hover, &:active, &:focus {
    opacity: 0.8
  }
}

.danger-text {
  color: $danger;
}

.banner {
  align-content: stretch;
  background-color: #fff;
  border: 2px solid $primary;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;

  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 90px;
  line-height: 18px;
  overflow: hidden;
  position: relative;
  width: 730px;


  @media (max-width: $mobile) {
    flex-direction: column;
    height: 100px;
    margin: 0 auto;
    width: 320px;
  }

  &__content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;

    @media (max-width: $mobile) {
      padding-bottom: 0;
    }
  }

  &__form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 0 15px;
    position: relative;

    @media (max-width: $mobile) {
      background-color: #FFE0C5;
      flex-direction: row;
      flex-grow: 0;
      height: 30px;
      justify-content: flex-start;
      padding: 2px 10px;

      & > * {
        flex: 1 0 auto;
        width: 50%;
      }
    }
  }

  &__hint-button {
    position: absolute;
    right: 13px;
    top: 8px;
    z-index: $zindex-button;

    @media (max-width: $mobile) {
      z-index: $zindex-content;
    }
  }

  &__input {
    border: 1px solid #707070;
    box-sizing: border-box;
    font-size: 16px;
    margin-right: 5px;
    padding: 3px 5px;
    width: calc(50% - 5px);
  }

  &__vehicle {
    @media (max-width: $mobile) {
      border-radius: 0;
    }

    [class$="title"] {
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}
.hide {
  &-mobile {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  &-desktop {
    @media (min-width: $mobile + 1) {
      display: none;
    }
  }
}