.banner-info-wrapp {
  display: flex;
  height: 100%;
}

.banner-info-container {
  background: #3d81a2;
  height: 100%;
  width: 100%;
}
.banner-info-body {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: none;
  height: 100%;
  justify-content: space-around;
  padding: 5px 24px 5px 17px;
  position: relative;
}

.banner-info-logo {
  flex-basis: 90px;
  margin-right: 15px;
}

.banner-info-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-info-header {
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.close-window-info {
  align-items: center;
  align-self: flex-start;
  background: #fff;
  border-radius: 50%;
  color: #3366ad;
  cursor: pointer;
  display: flex;
  font-size: 7px;
  height: 14px;
  justify-content: center;
  min-width: 14px;
  position: absolute;
  right: 6px;
}

.banner-info-icon {
  display: none;
}

@media screen and (min-width: 480px) {
  .banner-default .banner-info-icon {
    display: block;
    margin-right: 28px;
    max-height: 36px;
    max-width: 69px;
  }

  .banner-default .banner-info-icon img {
    display: block;
    max-height: 36px;
    max-width: 69px;
  }

  .banner-default .banner-info-message {
    align-items: center;
    display: flex;
  }

  .banner-default .banner-transit .banner-info-logo {
    flex-basis: 0;
    margin-right: 0;
  }
}
