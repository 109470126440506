.main-delivery {
  align-content: flex-start;
  display: flex;
  flex: auto;
  margin-bottom: 3px;
  overflow: hidden;
  padding: 4px 5px;
}

.main-input {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text-danger {
  font-size: 6px;
}

.logo-iaai {
  align-items: flex-start;
  display: flex;
  margin: 3px 3px 0 0;
}

.main-delivery-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
}

.main-delivery-city {
  align-content: flex-end;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(196, 196, 196, 0),
      #c4c4c4,
      rgba(196, 196, 196, 0)
    )
    47% 0;
  border-left: none;
  border-right: none;
  border-top: none;
  color: #000000;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 5px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.main-delivery-city-international {
  padding-bottom: 8px;
}

.main-delivery-city_wrap {
  display: flex;
  justify-content: space-between;
}

.city-list {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.city-list img {
  margin-right: 8px;
  max-height: 9px;
  max-width: 10px;
}

.city-from {
  flex: 1 1 auto;
  margin-right: 8px;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.city-to {
  flex: 1 1 auto;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-delivery {
  color: #4b5158;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  text-align: right;
}

.change-destination {
  background: none;
  border: none;
  color: #4c94d0;
  cursor: pointer;
  font-size: 9px;
  font-style: normal;
  line-height: normal;
  margin: 0;
  max-width: 110px;
  overflow: hidden;
  padding: 0;
  text-align: left;
  text-decoration-style: dotted;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-wrap button {
  border-bottom: 1px #4c94d0 dotted;
}

@media screen and (min-width: 480px) {
  .banner-default .main-delivery {
    flex-basis: auto;
    margin-bottom: 0;
  }

  .banner-default .main-delivery-city {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}