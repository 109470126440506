.main-transit {
  align-items: flex-start;
  bottom: 61px;
  box-sizing: border-box;
  color: #4b5158;
  display: flex;
  flex: none;
  font-size: 0.625rem;
  line-height: normal;
  padding: 4px;
  position: absolute;
  right: 2px;
}

.main-transit-international {
  bottom: 55px;
}

.main-transit-container {
  align-items: flex-start;
  display: flex;
  height: 100%;
}

.main-transit-head {
  display: flex;
}

.main-transit-text {
  color: #4b5158;
  font-size: 0.625rem;
  line-height: normal;
  margin-right: 5px;
  text-transform: capitalize;
}

.main-transit-days {
  color: #000;
  font-size: 0.625rem;
  font-weight: bold;
  line-height: normal;
  margin-right: 5px;
}

.mark-question {
  color: #a3a3a3;
  font-size: 0.625rem;
  line-height: 12px;
  text-align: center;
}
