.main {
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding: 2px;
  position: relative;
  width: 100%;
}

.shipping-block {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  padding: 12px 10px 0 0;
}

@media screen and (min-width: 480px) {
  .banner-default .main {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .banner-default .shipping-block {
    justify-content: space-evenly;
    padding: 12px 0 0 0;
  }
}
