@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Segoe_UI/Segoe_UI.woff2') format('woff2'),
    url('/fonts/Segoe_UI/Segoe_UI.woff') format('woff');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Segoe_UI/Segoe_UI_Bold.woff2') format('woff2'),
    url('/fonts/Segoe_UI/Segoe_UI_Bold.woff') format('woff');
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

#root {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
