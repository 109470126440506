.loading-container {
  margin: auto;
}
.loading-body {
  display: flex;
  flex: none;
}

.loading-logo {
  height: 24px;
  margin-right: 6px;
  width: 24px;
}

.loading-logo img {
  height: 100%;
  width: 100%;
}

.loading-content {
  display: flex;
  flex-direction: column;
  height: 24px;
}

.loading-header {
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.loading-text {
  color: #000000;
  font-size: 10px;
  line-height: normal;

  opacity: 0.4;
}
