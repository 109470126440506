.layout-stock {
  background-color: #fff;
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 0)
    ),
    url('/img/background-img.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.form-stock-lot {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 10.5% 0 21.5%;
  max-width: 495px;
}

.form-stock-lot-info {
  box-sizing: border-box;
  display: flex;
  flex-basis: 166px;
  flex-direction: column;
  position: relative;
  top: 3px;
}

.form-stock-lot-info span {
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
}

.form-stock-lot-info span:nth-child(2) {
  font-weight: 700;
}

.form-stock-lot-input {
  align-items: center;
  align-self: center;
  border: 1px solid #0c648c;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
  display: flex;
  height: 32px;
  max-width: 307px;
  padding: 3px;
  position: relative;
  width: 100%;
}

.form-stock-lot-input__border {
  position: relative;
  width: 100%;
}

.form-stock-lot-input__border .form-input {
  min-width: 250px;
  width: 100%;
}

.form-stock-lot-input__border input {
  background: transparent;
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  height: 100%;
  outline: none;
  padding-left: 10px;
  width: 68%;
}

.form-stock-lot-input__border input::-ms-clear {
  display: none;
}

.stock-blur-input {
  padding: 0;
  width: 100% !important;
}

.stock-blur-input::placeholder {
  color: #000;
  opacity: 1;
  transition: 0.3s;
}

.stock-blur-input:focus::placeholder {
  opacity: 0.2;
}

.stock-label {
  background-color: #fff;
  color: #0c648c;
  font-size: 10px;
  left: 8px;
  opacity: 0;
  padding: 1px 3px;
  position: absolute;
  top: -6px;
  transition: 0.3s;
}

.stock-blur-input:focus + .stock-label {
  opacity: 1;
}

.form-stock-lot-input__border img {
  cursor: pointer;
  height: 10px;
  margin-top: -5px;
  position: absolute;
  right: 81px;
  top: 50%;
  width: 10px;
}

.form-stock-lot-input__border button {
  background: #0e658d;
  border: none;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  max-width: 74px;
  outline: none;
  padding: 0 16px;
}

.form-stock-lot-input__border button:disabled {
  background-color: rgba(14, 101, 141, 0.2);
}

.form-stock-lot-option {
  align-items: center;
  border-color: #3366ad;
  color: #3366ad;
  display: flex;
  flex-basis: 76px;
  font-weight: 700;
  height: 13px;
  max-width: 0.875rem;
  min-height: 13px;
  min-width: 13px;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
}

.mark-question {
  color: #3366ad;
}

.bannerstock .banner-info-logo--img {
  left: 0;
  position: absolute;
  top: -23px;
  width: 75px;
}

.bannerstock .form-input {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.stock-valid-error {
  color: #8c0c0c;
  font-size: 10px;
  left: 0;
  position: absolute;
  top: 112%;
}

.stock-error {
  border: 1px solid #8c0c0c;
}
