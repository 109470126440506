.loader250 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.loader250-main {
  flex-grow: 1;
  padding-bottom: 40px;
  padding-top: 87px;
}

.loader250-spinner-block {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 80px;
  transform: translate(-50%, 0);
}

.loader250-text {
  font-size: 10px;
  margin: 0 0 0 5px;
}

.loader {
  animation: spin 1s linear infinite;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #3498db;
  height: 14px;
  width: 14px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader250-bottom {
  background-color: rgba(57, 143, 185, 0.05);
  padding: 14px 15px 17px;
}

.loader250-bottom-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.loader250-bottom-title {
  background-color: rgba(226, 240, 255, 0.3);
  height: 20px;
  width: 161px;
}

.loader250-bottom-price {
  background-color: rgba(226, 240, 255, 0.3);
  height: 20px;
  width: 76px;
}

.loader250-bottom-button {
  background-color: rgba(226, 240, 255, 0.3);
  border-radius: 17px;
  height: 34px;
  width: 264px;
}
