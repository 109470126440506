.custom-main-delivery {
  display: flex;
}

.custom-main-delivery__content {
  font-size: 14px;
  margin: 0 auto;
  max-width: 340px;
  text-align: center;
}
.custom-main-delivery__hint {
  background-color: transparent;
  border: 1px solid #3366ad;
  border-radius: 50%;
  color: #3366ad;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  -moz-osx-font-smoothing: inherit;
  -webkit-font-smoothing: inherit;
  font-weight: 700;
  height: 14px;
  line-height: 12px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  text-align: center;
  width: 14px;
}

.custom-main-delivery__title {
  font-weight: 600;
  margin-bottom: 5px;
}
.custom-main-delivery__label {
  font-size: 14px;
  line-height: 16px;
}

.logo-iaai__custom {
  display: none;
}

@media screen and (max-width: 480px) {
  .logo-iaai__custom {
    display: flex;
  }
}
