.loader-stock {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
}

.loader-stock-content {
  align-items: center;
  display: flex;
}

.loader-stock .loader {
  border-top-color: #b7b7b7;
  border-top-width: 2px;
  margin-right: 6px;
}

.loader-stock-text {
  color: #b7b7b7;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}
