.banner-stock-transit {
  align-items: center;
  background-color: #ffead8;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 12px 27px;
  position: relative;
  width: 100%;
}

.banner-stock-transit-logo {
  flex: none;
  margin-right: 39px;
}

.banner-stock-transit-text {
  color: #000;
  font-size: 14px;
  line-height: normal;
}

.banner-stock-transit-text p {
  margin: 3px auto;
}

.close-transit {
  cursor: pointer;
  display: flex;
  height: 10px;
  position: absolute;
  right: 9px;
  top: 10px;
  width: 10px;
}

.close-window-stock-transit img {
  fill: #000;
  height: 10px;
  width: 10px;
}
