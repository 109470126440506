$primary: #E97108;
$primary-lighter: #FFE0C5;
$primary-lighten: #FFEAD8;
$primary-dark: #FBC79D;

$secondary-100: #CCE7FF;
$secondary-400: #009BFF;
$secondary-600: #0071BC;
$secondary-1000: #3366AD;
$secondary-1200: #00688F;

$primary-iaai: #1D67B2;
$secondary-iaai: #009BFF;

$danger: #980000;

$grey: #C2C2C2;

$zindex-button: 1;
$zindex-modal: 100;
$zindex-content: 10;

$mobile: 727px;

@mixin reset-btn {

  -webkit-appearance: none;

  background-color: transparent;
  border: none;

  color: inherit;
  cursor: pointer;
  font: inherit;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
