.main-delivery-input {
  align-content: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 12px 8px;
  position: relative;
  width: 100%;
}

.main-delivery-input input {
  font-size: 12px;
  padding-left: 9px;
}

.banner-default .input-autocomplete {
  padding-left: 9px !important;
}

.form-input {
  box-sizing: border-box;
  height: 30px;
  position: relative;
  z-index: 2;
}

input:focus {
  border: 2px solid #fa762e;
}

.text-danger {
  bottom: 0;
  color: #94340e;
  font-size: 0.625rem;
  line-height: normal;
  min-height: 0.875rem;
  position: absolute;
  width: 100%;
}

.select {
  border: 1px solid #fa762e;
  box-shadow: none;
  box-sizing: border-box;
  height: 30px !important;
  padding: 1px 1px 1px 9px;
}

.select:hover {
  border: 2px solid #fa762e;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0 0 0 8px;
}

.select:hover .eh-select__menu {
  border: 2px solid #fa762e;
  border-top: none;
  left: -2px;
  padding: 0;
  top: calc(100% + 1px) !important;
  width: calc(100%) !important;
}

.select:active {
  border: 2px solid #fa762e;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 0 0 0 8px;
}

.select .eh-select__control {
  height: 26px !important;
  min-height: 26px !important;
}

.select--places .eh-select__dropdown-indicator {
  display: none;
}

.select--places .eh-select__option {
  font-size: 12px;
}

.eh-select__control {
  align-items: flex-start !important;
  border-color: initial !important;
  border-radius: 0 !important;
  border-style: none !important;
}

.eh-select__control--is-focused,
.eh-select__control--is-open {
  border-style: none !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  height: 30px !important;
}

select .eh-select__control--is-focused {
  height: 30px !important;
}

select .eh-select__control--is-open {
  height: 31px !important;
}

.css-uxmdvz-control {
  align-items: flex-start;
  border-style: none !important;
  display: flex;
  min-height: 30px !important;
}

.css-bgvzuu-indicatorSeparator,
.select__indicator-separator {
  display: none;
}

.eh-select__menu {
  border: 1px solid #fa762e;
  border-radius: 0 !important;
  border-top: none;
  box-sizing: content-box !important;
  height: 115px;
  left: -1px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  overflow: hidden;
  padding: 0 1px;
  width: calc(100% + -2px) !important;
  z-index: 5;
}

.banner-default .eh-select__menu {
  height: 70px;
}

.eh-select__menu-list {
  background-color: rgba(255, 255, 255, 0.1);
  height: 9.375rem;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.eh-select__menu-list::-webkit-scrollbar {
  width: 0;
}
.eh-select__control .eh-select__value-container {
  padding: 0;
}

.eh-select__control .eh-select__value-container--has-value {
  padding: 0;
}

.eh-select__control .eh-select__indicators {
  height: 26px !important;
}

.eh-select__placeholder {
  font-size: 12px;
}

.input-autocomplete {
  border: 1px solid #fa762e;
  padding: 1px !important;
}
.input-autocomplete::-ms-clear {
  display: none;
}

.input-autocomplete:hover {
  border: 2px solid #fa762e;
  box-sizing: border-box;
  padding: 1px 1px 1px 8px;
}

.input-autocomplete:active {
  border: 2px solid #fa762e;
  box-sizing: border-box;
  padding: 1px 1px 1px 8px;
}

.input-autocomplete:focus {
  border: 2px solid #fa762e;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  outline: none;
  padding: 1px 1px 1px 8px;
}

.input-autocomplete-error {
  border: 2px solid #94340e;
  padding: 0 1px 1px 8px;
}

.pac-container {
  max-height: 60px !important;
  overflow-y: auto;
}

@media screen and (min-width: 480px) {
  .banner-default .main-delivery-input {
    flex-basis: 49%;
    padding: 0 10px 0 8px;
  }

  .banner-default .select {
    margin-left: 0;
    padding-left: 9px;
  }

  .banner-default .select:hover {
    padding-left: 8px;
  }

  .banner-default .text-danger {
    bottom: 10px;
  }
}

html[lang='es'] .eh-select__placeholder {
  font-size: 10px;
}
