.main-quote {
  align-items: center;
  background: #3d81a2;
  box-sizing: border-box;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  padding: 7px 10px;
}

.quote-button {
  flex: none;
  order: 2;
}

.quote-button button,
.quote-button a {
  background: #fa762e;
  border: 0;
  border-radius: 90px;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  height: 36px;
  line-height: normal;
  min-width: 8.125rem;
  outline: none;
  text-align: center;
}

.quote-button a {
  line-height: 34px;
  text-decoration: none;
}

.quote-button button:hover,
.quote-button a:hover {
  background-color: #ffa26f;
}

.quote-button button:active,
.quote-button a:active {
  background-color: #cf4900;
}

.quote-cancel {
  cursor: pointer;
}

.quote-cancel span {
  color: #ffffff;
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .banner-default .main-quote {
    margin-left: 10px;
    max-width: 211px;
    padding: 7px 10px;
  }

  .banner-default .quote-button {
    order: -1;
  }

  .banner-default .quote-button button,
  .banner-default .quote-button a {
    height: 34px;
    line-height: inherit;
    width: 146px;
  }

  .banner-default .custom-quote-button a {
    line-height: 34px;
    width: 100%;
  }

  .banner-default .quote-cancel span {
    display: block;
    line-height: inherit;
  }
}
