/* THEME LIST:
banner144 banner250 banner154 banner80 banner-default */
.wrapp {
  background: #ffffff;
  border: 1px solid #3366ad;
  box-sizing: border-box;
  display: flex;
  height: 148px;
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.bannerstock-background {
  background: #fcf4e7;
  height: 100%;
}

.wrapp-loading-color {
  background: #e2f0ff;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 480px) {
  .wrapp {
    height: 80px;
  }
}

.banner250 {
  display: block !important;
  max-height: 100% !important;
  max-width: 730px !important;
  min-height: 250px;
}

.banner144 {
  display: block !important;
  max-height: 144px !important;
  max-width: 300px !important;
  min-height: 144px;
}

.bannerstock {
  border: 2px solid #e97108;
  box-sizing: border-box;
  display: block;
  height: 90px;
  max-width: 728px;
}

.bannerstock * {
  box-sizing: border-box;
}

.bannerstock .container {
  display: block;
  height: 100%;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
