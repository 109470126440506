.banner-default {
  &.wrapp {
     height: 120px;
     max-width: 730px;
   }

  .main-order {
    max-width: 200px;
  }

  .main-transit::before {
    height: 40px;
    left: 0;
    position: absolute;
    top: 15px;
  }

  .main-transit-container {
    height: auto;
  }
  .main-transit {
    align-items: flex-start;
  }
  .logo-iaai {
    margin-right: 8px;
    margin-top: 4px;
  }

  .logo-iaai img {
    transform: scale(1.5);
  }

  .main-delivery-city_wrap + .main-delivery-city_wrap {
    margin-top: 5px;
  }
  .change-destination {
    font-size: 12px;
    line-height: 14px;
  }
  .main-order {
    flex-direction: column;
  }
  .main-order-price {
    flex-direction: row;
    height: auto;
  }
  .main-order-price_number span {
    font-size: 14px;
    line-height: 16px;
  }
  .main-order-price_text span {
    font-size: 14px;
    line-height: 16px;
  }
  .main-order-price {
    align-items: center;
    margin-bottom: 3px;
  }
  .main-order {
    justify-content: center;
  }

  .main-transit-days {
    flex-basis: 100%;
    font-size: 14px;
    line-height: 16px;
    margin-right: 0;
    margin-top: 5px;
    order: 3;
  }

  .header-option {
    flex-basis: 14px;
    height: 14px;
    order: 2;
  }

  .eh-select__menu {
    max-height: 58px;
  }

  .eh-select__menu-list {
    max-height: 58px;
  }

  .custom-main-delivery__content {
    margin-top: -5px;
  }

  @media (min-width: 480px) {
     .main-transit::before {
      background-image: linear-gradient(
                      180deg,
                      rgba(196, 196, 196, 0) -1.28%,
                      #c4c4c4 48.08%,
                      rgba(196, 196, 196, 0) 100%
      );
      content: '';
      display: block;
      margin-right: 10px;
      width: 1px;
    }

     .main-transit {
      bottom: 0;
      flex-basis: 140px;
      max-width: 140px;
      padding: 18px 15px 4px 10px;
      position: relative;
      right: 0;
    }

    .main-delivery {
      padding-top: 18px;
    }

     .main-transit-container {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .button-wrap {
      margin-top: 4px;
    }

     .main-transit-text {
      flex-basis: calc(100% - 19px);
      order: 1;
      text-transform: uppercase;
    }

     .main-transit-days {
      flex-basis: 100%;
      font-size: 14px;
      line-height: 16px;
      margin-right: 0;
      order: 3;
    }

     .header-option {
      flex-basis: 14px;
      height: 14px;
      order: 2;
    }

    .main-delivery {
      align-items: flex-start;
      flex-basis: auto;
      margin-bottom: 0;
    }
    .main-delivery-city {
      border-bottom: none;
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 0;
    }

    .main-transit-text {
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
    }
  }
}









