.loader144 {
  position: relative;
}

.loader144-main {
  padding-bottom: 20px;
  padding-top: 38px;
}

.loader144-spinner-block {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 30px;
  transform: translate(-50%, 0);
}

.loader144-bottom {
  align-items: center;
  background-color: rgba(57, 143, 185, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 8px 11px;
}

.loader144-bottom-info {
}

.loader144-bottom-title {
  background-color: rgba(226, 240, 255, 0.3);
  height: 13px;
  margin-bottom: 5px;
  width: 128px;
}

.loader144-bottom-price {
  background-color: rgba(226, 240, 255, 0.3);
  height: 26px;
  width: 90px;
}

.loader144-bottom-button {
  background-color: rgba(226, 240, 255, 0.3);
  border-radius: 17px;
  height: 34px;
  width: 120px;
}
