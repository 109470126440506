.header {
  background: #e2f0ff;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem;
}

.header-title {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.2;
  margin-right: 5px;
  overflow: hidden;
  text-transform: uppercase;
  white-space: nowrap;
}

.header-title-logo {
  margin-right: 0.25rem;
}

.header-title_guaranteed {
}

.header-title_name-car {
  display: inline-flex;
  overflow: hidden;
}

.header-title_name-make {
  direction: ltr;
  display: inline-block;
}

.header-option {
  border: 1px solid #a3a3a3;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  max-height: 0.875rem;
  min-width: 0.875rem;
  transition: all 0.3s;
}

.header-option:hover {
  border: 1px solid #3366ad;
}

.header-option:hover span {
  color: #3366ad;
}

.header-option:active {
  border: 1px solid #0059a3;
}

.header-option:active span {
  color: #0059a3;
}

.mark-question {
  color: #a3a3a3;
  font-size: 0.625rem;
  line-height: normal;
  text-align: center;
  transition: all 0.3s;
}

@media screen and (min-width: 480px) {
  .header {
    padding: 5px;
  }
}
