.salvagebid250 {
  background-color: #fff;
  border: 1px solid #b8291b;
  box-sizing: border-box;
  color: #101010;
  height: 250px;
  left: -1px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 300px;
}

.salvagebid250__header {
  margin-bottom: 20px;
  padding-top: 15px;
}

.salvagebid250__logo-group {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 10px;
}

.salvagebid250__badge {
  align-items: center;
  background-color: #ffab00;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: 19px;
  justify-content: space-between;
  margin-left: 10px;
  padding: 4px 10px 4px 4px;
  width: 120px;
}

.salvagebid250__badge-text {
  font-size: 10px;
  font-weight: 700;
}

.salvagebid250__title {
  font-size: 30px;
  font-weight: 900;
  line-height: 24px;
  margin: 0 0 10px;
}

.salvagebid250__text {
  border-bottom: 1px solid #c4c4c4;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin: 0 30px;
  padding-bottom: 22px;
  text-transform: uppercase;
  white-space: nowrap;
}

.salvagebid250__text-car {
  color: #525152;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 0 30px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.salvagebid250__button {
  align-items: center;
  background-color: #b8291b;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  line-height: 1;
  margin: auto;
  text-decoration: none;
  width: 220px;
}

.salvagebid250--accent {
  border-color: #8e0000;
  color: #fff;
  font-weight: 600;
}

.salvagebid250--accent .salvagebid250__header {
  background: linear-gradient(148.41deg, #8e0000 21.28%, #8e0000 72.67%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  /* Temporary fix for production layout */
  margin-top: -1px;
}
.salvagebid250--accent .salvagebid250__badge {
  display: none;
}

.salvagebid250--accent .salvagebid250__text {
  border-bottom: none;
}

.salvagebid250--accent .salvagebid250__text-car {
  font-size: 16px;
  line-height: 19px;
}

.salvagebid250--accent .salvagebid250__button {
  background-color: #ffab00;
  border: 1px solid #e59a00;
  border-radius: 90px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 1px 4px rgba(0, 0, 0, 0.05);
  color: #000;
  height: 36px;
  text-transform: uppercase;
}
