.banner250 .header {
  padding: 6px;
}
.banner250 .header-option {
  border: 1px solid #3366ad;
}

.banner250 .mark-question {
  color: #3366ad;
  font-weight: 700;
}

.banner250 .main {
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  height: auto;
  padding-top: 7px;
}

.banner250 .logo-iaai {
  display: none;
}

.banner250 .main-delivery {
  height: 119px;
  margin-bottom: 0;
  padding: 0;
}

.banner250 .main-delivery-content {
  padding: 0 15px 15px 15px;
}

.banner250 .main-delivery-city {
  font-size: 18px;
  height: 79px;
  margin-bottom: 13px;
  padding-bottom: 0;
  text-transform: capitalize;
}

.banner250 .main-delivery-city-international {
  margin-bottom: 13px;
}

.banner250 .main-delivery-city_wrap {
  padding-bottom: 13px;
}

.banner250 .main-delivery-list {
  list-style: none;
  margin: 0;
  padding: 2px 0 0;
}

.banner250 .delivery-from {
  margin-bottom: 5px;
  position: relative;
}

.banner250 .delivery-from span,
.banner250 .delivery-to span {
  display: inline-block;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.banner250 .delivery-from:before,
.banner250 .delivery-to::before {
  background-color: #0c648c;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px #e2f0ff;
  content: '';
  display: inline-block;
  height: 5px;
  margin-bottom: 2px;
  margin-left: -6px;
  margin-right: 7px;
  position: relative;
  top: -5px;
  width: 5px;
}

.banner250 .delivery-from:after {
  background-image: url('/img/dots.svg');
  content: '';
  display: inline-block;
  height: 9px;
  left: -3px;
  position: absolute;
  top: 25px;
  width: 1px;
}

.banner250 .delivery-price {
  align-self: flex-end;
  color: #4b5158;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  position: relative;
  top: -5px;
}

.banner250 .change-destination {
  border-bottom: none;
}

.banner250 .main-transit {
  bottom: 109px;
  padding: 0;
  right: 15px;
}

.banner250 .main-order {
  flex: 1 0 auto;
  flex-wrap: wrap;
  height: 94px;
  justify-content: flex-start;
  padding: 13px 15px;
}

.banner250 .main-order-price {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  height: initial;
  margin-bottom: 10px;
}

.banner250 .main-order-price_text span {
  font-size: 18px;
  white-space: nowrap;
}

.banner250 .main-order-button {
  flex-basis: 100%;
}

.banner250 .main-order-button a {
  box-sizing: border-box;
  line-height: 34px;
  margin: 0 auto;
  max-width: 300px;
}

.banner250 .main-delivery-input {
  flex-basis: auto;
  margin: 0 auto 25px;
  max-width: 300px;
  padding: 0;
}

.banner250 .main-quote {
  flex-basis: auto;
  flex-wrap: wrap;
  height: 94px;
  padding: 14px 15px;
}

.banner250 .quote-button {
  flex-basis: 100%;
  order: 0;
}

.banner250 .quote-button button,
.banner250 .quote-button a {
  display: block;
  height: 34px;
  margin: 0 auto 10px;
  max-width: 300px;
  width: 100%;
}

.banner250 .quote-cancel {
  line-height: 20px;
  text-align: center;
  width: 100%;
}

.banner250 .quote-cancel span {
  font-size: 14px;
}

.banner250 .text-danger {
  bottom: -17px;
  line-height: 10px;
  min-height: 10px;
}

.banner250 .custom-main-delivery {
  color: #000;
  flex-basis: 0;
  font-size: 16px;
  font-weight: normal;
  height: auto;
  line-height: 19px;
  padding-top: 8px;
  text-align: center;
  text-transform: none;
}

.banner250 .custom-main-delivery__content {
  max-width: 240px;
}

.banner250 .content-custom-delivery-part {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.banner250 .custom-main-delivery .button-wrap {
  border-bottom: none !important;
  border-image: linear-gradient(
      to right,
      rgba(196, 196, 196, 0),
      #c4c4c4,
      rgba(196, 196, 196, 0)
    )
    47% 0% !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid !important;
  margin-top: 15px;
  padding: 10px 0;
}

.banner250 .custom-main-delivery .button-wrap button {
  font-size: 12px;
  margin: auto;
}

.banner250 .custom-quote-button {
  margin: 0 auto;
  max-width: 300px;
}

.banner250 .custom-quote {
  height: 68px;
}

.banner250 .banner-info-wrapp {
  background-color: #fff;
  box-sizing: border-box;
  padding: 2px;
}

.banner250 .banner-info-body {
  padding: 0;
}

.banner250 .banner-info-logo {
  left: 15px;
  margin-right: 0;
  position: absolute;
  top: 12px;
}

.banner250 .banner-info-logo--img {
  display: block;
  height: 14px;
  width: 56px;
}

.banner250 .close-window-about {
  right: 4px;
  top: 4px;
}

.banner250 .banner-info-content {
  align-items: center;
  flex-direction: row;
  padding: 0 10px 0 15px;
}

.banner250 .banner-info-content span {
  font-size: 12px;
  line-height: 15px;
}

.banner250 .banner-info-message {
  display: flex;
}

.banner250 .close-window-info {
  right: 4px;
  top: 4px;
}

.banner250 .banner-info-icon {
  align-items: center;
  background-color: #1f6689;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  min-height: 37px;
  min-width: 37px;
}

.banner250 .banner-info-icon-car {
  align-self: flex-start;
}

.banner250 .banner-info-logo--icon {
  width: 26px;
}

.banner250 .banner-info-logo--icon-no-reverse {
  transform: rotateY(0deg);
}
/* shipping block */
.banner250 .main-international-shipping {
  box-sizing: border-box;
  display: block;
  height: 38px;
  margin: auto;
  margin-bottom: 22px;
  max-width: 300px;
  order: -1;
  padding-top: 5px;
  width: 100%;
}

.banner250 .shipping-label {
  align-items: stretch;
  color: #3366ad;
  display: flex;
  font-size: 12px;
  margin-bottom: 5px;
  position: relative;
}

.banner250 .shipping-label:last-child {
  margin-bottom: 0;
}

.banner250 .shipping-radio {
  display: none;
}

.banner250 .shipping-text {
  align-items: center;
  display: flex;
}

.banner250 .shipping-text::before {
  border: 1px solid #3366ad;
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 12px;
  margin-right: 4px;
  width: 12px;
}

.banner250 .shipping-text::after {
  background-color: #3366ad;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px #3366ad;
  content: '';
  height: 4px;
  left: 2px;
  opacity: 0;
  position: absolute;
  top: 2px;
  width: 4px;
}

.banner250 .shipping-radio:checked + .shipping-text::after {
  opacity: 1;
}

.banner250 .eh-select__menu-list {
  max-height: 116px;
}

/* international shipping  */

.banner250 .select {
  height: 34px !important;
  margin-left: 0;
}

.banner250 .eh-select__indicators {
  height: 30px !important;
}

.banner250 .eh-select__value-container {
  height: 30px !important;
  white-space: nowrap;
}

.banner250 .delivery-price-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.banner250 .delivery-from-international {
  margin-bottom: 0;
}

.banner250 .delivery-from-port {
  font-weight: 400;
}

.banner250 .delivery-from-international::after {
  top: 20px;
}

.banner250 .delivery-from-international,
.banner250 .delivery-from-port,
.banner250 .delivery-to-international {
  height: 22px;
}

.banner250 .delivery-price-ocean {
  margin-bottom: -7px;
}

.banner250 .main-delivery-city_wrap-international {
  font-size: 17px;
  padding-bottom: 10px;
}

.banner250 .form-input {
  height: 34px;
}

.banner250 .input-autocomplete {
  padding-left: 9px !important;
}

html:not[lang='en'] .banner250 .main-order-price_text span {
  font-size: 16px;
}

html[dir='rtl'] .banner250 .main-transit {
  left: 15px;
  right: auto;
}

html[dir='rtl'] .main-transit-days {
  margin-left: 5px;
}

html[dir='rtl'] .banner250 .delivery-from:after {
  left: auto;
  right: -3px;
}

html[dir='rtl'] .banner250 .delivery-from:before,
html[dir='rtl'] .banner250 .delivery-to::before {
  margin-left: 7px;
  margin-right: -6px;
}

html[dir='rtl'] .banner250 .banner-info-icon-car {
  margin-left: 10px;
  margin-right: 0;
}
html[dir='rtl'] .banner250 .shipping-text::before {
  margin-left: 4px;
  margin-right: auto;
}

html[dir='rtl'] .banner250 .shipping-text::after {
  left: auto;
  right: 2px;
}

html[lang='pl'] .banner250 .custom-main-delivery .button-wrap {
  margin-top: 25px;
}
