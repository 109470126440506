.main-order {
  align-items: center;
  background: #3d81a2;
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-basis: 100%;
  justify-content: space-between;
  padding: 7px 10px;
}

.main-order-price {
  display: flex;
  flex: none;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.main-order-price_text span {
  color: #ffffff;
  display: block;
  font-size: 10px;
  line-height: normal;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-order-price_number {
  color: #fff;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-order-price_number span {
  color: #ffffff;
  font-size: 1.37rem;
  font-weight: bold;
  line-height: 1.37rem;
}

.main-order-button {
  display: flex;
  flex: none;
}

.main-order-button a {
  background: #fa762e;
  border: 0;
  border-radius: 90px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 36px;
  outline: none;
  padding: 0 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.main-order-button a:hover {
  background-color: #ffa26f;
}

.main-order-button a:active {
  background-color: #cf4900;
}

@media screen and (min-width: 480px) {
  .banner-default .main-order {
    max-width: 211px;
  }
}

@media screen and (min-width: 480px) and (max-width: 495px) {
  .banner-default .main-order {
    max-width: 200px;
  }
}
