.err-data-body {
  align-items: center;
  background: #fff2f2;
  box-sizing: border-box;
  display: flex;
  flex: none;
  height: 100%;
  justify-content: space-around;
  padding: 5px;
}

.err-data-text {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: normal;
}

.blue-color-text {
  color: #3366ad;
  cursor: pointer;
}
