.banner-info-wrapp {
  display: flex;
  height: 100%;
}

.banner-info-container {
  background: #3d81a2;
  height: 100%;
  width: 100%;
}
.banner-info-body {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: none;
  height: 100%;
  justify-content: space-around;
  padding: 5px;
  position: relative;
}

.banner-info-logo {
  flex-basis: 90px;
  margin-right: 15px;
}

.banner-info-content {
  display: flex;
  flex-basis: 416px;
  flex-direction: column;
  height: 100%;
}

.banner-info-content span {
  color: #ffffff;
  font-size: 12px;
  line-height: normal;
}

.banner-info-header {
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.banner-info-text {
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto;
  max-width: 270px;
}

.banner-info-text__desc {
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px
}

.banner-info-text a {
  color: #fff;
}

.close-window-about {
  align-items: center;
  align-self: flex-start;
  background: #fff;
  border-radius: 50%;
  color: #3366ad;
  cursor: pointer;
  display: flex;
  font-size: 7px;
  height: 14px;
  justify-content: center;
  min-width: 14px;
  position: absolute;
  right: 6px;
}

@media screen and (max-width: 480px) {
  .banner-info-logo--img {
    max-width: 56px;
  }
  .banner-info-content span {
    color: #ffffff;
    display: block;
    font-size: 11px;
    line-height: 14px;
  }
}

@media screen and (min-width: 480px) {
  .banner-default .banner-info-body {
    padding: 5px 25px 5px 12px;
  }

  .banner-default .banner-info-logo {
    margin-right: 18px;
  }

  .banner-default .banner-info-content {
    flex-basis: 1000%;
  }
}
